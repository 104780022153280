<template>
  <Layout class="MenuEdit">
    <el-form
      ref="subFormData"
      :model="subFormData"
      size="small"
      label-width="150px"
      label-position="left"
      class="form"
      :rules="rules"
    >
      <el-form-item label="排序">
        <el-input v-model="subFormData.rank" />
      </el-form-item>
      <el-form-item label="菜单名称：">
        <el-input v-model="subFormData.name" />
      </el-form-item>
      <el-form-item
        label="菜单图标："
        prop="swiper"
      >
        <div class="nonfocal-box">
          <div class="nonfocal">
            <UploadImage
              :limit="1"
              class="image"
              :file-list.sync="iconOne"
            />
            <!-- <h4>非焦点状态</h4> -->
          </div>
          <!-- <div class="nonfocal">
            <UploadImage
              :limit="1"
              class="image"
              :file-list.sync="iconTwo"
            />
            <h4>焦点状态</h4>
          </div> -->
        </div>
      </el-form-item>
      <el-form-item label="外显包:">
        <el-checkbox-group
          v-model="subFormData.app_keys"
        >
          <el-checkbox
            v-for="(item, key) in appKeysNameMap"
            :key="key"
            :label="key"
          >
            {{ item }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <template
        v-if="subFormData.app_keys.length"
      >
        <div
          v-for="(key, item) in appKeysNameMap"
          :key="item"
        >
          <el-form
            v-if="subFormData.app_keys.indexOf(item) > -1"
            ref="subFormDataSettings"
            :model="subFormData.settings[item]"
            label-width="150px"
            :rules="rules"
          >
            <el-divider />
            <div style="margin:20px 0 30px 120px;font-weight:bold">
              {{ `${key}配置` }}
            </div>
            <el-form-item
              label="点击类型"
              prop="redirect_type"
            >
              <el-select
                v-model="subFormData.settings[item].redirect_type"
              >
                <el-option
                  v-for="(regionTypeitem, regionTypekey) in clickType"
                  :key="regionTypekey"
                  :label="regionTypeitem "
                  :value="regionTypekey"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              label="跳转链接"
              prop="redirect_url"
            >
              <el-input v-model="subFormData.settings[item].redirect_url" />
            </el-form-item>
            <el-form-item
              label="面向国家"
              prop="region_type"
            >
              <el-select
                v-model="subFormData.settings[item].region_type"
              >
                <el-option
                  v-for="(regionTypeitem, regionTypekey) in regionTypeObj"
                  :key="regionTypekey"
                  :label="regionTypeitem "
                  :value="regionTypekey"
                />
              </el-select>
            </el-form-item>
            <div v-if="subFormData.settings[item].region_type != 'all'">
              <el-form-item

                label="国家列表"
                prop="region_code"
              >
                <el-input
                  v-model="subFormData.settings[item].region_code"
                />
                <span style="color:red">
                  多个国家使用” , “号隔开
                </span>
              </el-form-item>
            </div>
            <el-form-item
              label="类目ID关联列表"
              prop="module_id"
            >
              <el-input v-model="subFormData.settings[item].module_id" />
            </el-form-item>
            <el-form-item
              label="外显版本"
              prop="version"
            >
              <el-input
                v-model.number="subFormData.settings[item].version[0]"
                style="width:91px"
              />
              <span style="padding: 0 6px">-</span>
              <el-input
                v-model.number="subFormData.settings[item].version[1]"
                style="width:91px"
              />
            </el-form-item>
            <!-- </el-form> -->
          </el-form>
          <!-- </div> -->
        </div>
      </template>
      <el-form-item
        label="状态"
        prop="status"
      >
        <el-radio-group v-model="subFormData.status">
          <el-radio
            v-for="(item,key) in stateObj"
            :key="key"
            :label="key * 1"
          >
            {{ item }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button
          :loading="addLoading"
          type="primary"
          @click="onSubmit"
        >
          保存
        </el-button>
      </el-form-item>
    </el-form>
  </Layout>
</template>

<script>
import { commonRequestGet } from '@/api/commonApi.js'
import { createMenu, updateMenu } from '@/api/menu-disposition.js'

const regionTypeObj = {
  all: '全部国家',
  display: '部分国家显示',
  hide: '部分国家隐藏'
}
const clickType = {
  client: '客户端页面',
  h5: 'H5链接'
}
const stateObj = {
  1: '上架',
  0: '下架'
}
export default {
  components: {

  },
  data () {
    const swiperValidator = (rule, value, callback) => {
      if (this.iconOne.length) {
        return callback()
      } else {
        callback(new Error('必填'))
      }
    }
    return {
      regionTypeObj,
      clickType,
      stateObj,
      row: '',
      iconOne: [],
      iconTwo: [],
      subFormData: {
        position: 'discover',
        icon: '',
        focus_icon: '',
        name: '',
        rank: 0,
        status: 0,
        app_keys: [],
        settings: {}
      },
      rules: {
        swiper: [{ required: true, validator: swiperValidator }],
        redirect_type: [{ required: true, message: '必填', trigger: 'change' }],
        redirect_url: [{ required: true, message: '必填', trigger: 'blur' }],
        region_type: [{ required: true, message: '必填', trigger: 'change' }],
        region_code: [{ required: true, message: '必填', trigger: 'blur' }],
        module_id: [{ required: true, message: '必填', trigger: 'blur' }]
      },
      addLoading: false,
      appKeysNameMap: []
    }
  },
  watch: {
    'subFormData.app_keys': {
      handler (newValue) {
        newValue.forEach((item) => {
          if (!this.subFormData.settings) this.subFormData.settings = {}
          if (!this.subFormData.settings[item]) {
            this.$set(this.subFormData.settings, item, {
              redirect_type: 'client',
              region_type: 'all',
              region_code: '',
              redirect_url: '',
              // advert_size: '',
              // advert_id: '',
              version: [
                '',
                ''
              ]
            })
          }
        })
      },
      immediate: true,
      deep: true
    }
  },
  async created () {
    await this.getAppKeys()
    this.row = this.$route.query.row
    if (this.row && this.row.id) {
      if (this.row.icon) {
        this.iconOne = [{ url: this.row.icon }]
      }
      if (this.row.focus_icon) {
        this.iconTwo = [{ url: this.row.focus_icon }]
      }
      this.subFormData = this.$route.query.row
    }

    console.log(this.subFormData) // 输出row
  },

  methods: {
    // 外显包
    async getAppKeys () {
      const res = await commonRequestGet('admin/app-review', this.formData)
      // console.log(res, '1111111')
      this.appKeysList = res?.data ?? []
      // 当排序设置为0时，不在应用下拉框内显示
      this.filteredAppKeysList = this.appKeysList.filter(item => item.rank !== 0)
      this.appKeysList = this.filteredAppKeysList.map(item => {
        return {
          app_key: item.app_id,
          name: item.app_name
        }
      })
      console.log(this.appKeysList)
      this.appKeysNameMap = this.appKeysList.reduce((result, item) => {
        result[item.app_key] = item.name
        return result
      }, {})
      // console.log(this.appKeysNameMap
    },
    // 保存
    onSubmit () {
      try {
        this.addLoading = true
        this.$refs.subFormData.validate(valid => {
          if (valid) {
            this.$refs.subFormDataSettings.map(item => {
              item.validate(valid => {
                if (valid) {
                  if (this.row && this.row.id) {
                    const id = this.row.id
                    console.log(id, '1111111')
                    // updateMenu(id, this.subFormData)
                    updateMenu(id, { ...this.subFormData, icon: this.iconOne[0]?.url || '', focus_icon: this.iconTwo[0]?.url || '' })
                    this.$router.push({ name: 'discover' })
                    this.$message.success('编辑成功')
                  } else {
                    createMenu({ ...this.subFormData, icon: this.iconOne[0]?.url, focus_icon: this.iconTwo[0]?.url })
                    this.$router.push({ name: 'discover' })
                    this.$message.success('创建成功')
                  }
                }
              })
            })
          }
        })
      } catch (error) {
        this.$message.error(error.response.data.message)
      } finally {
        this.addLoading = false
      }
    }
  }
}
</script>

<style lang="less">
.MenuEdit {
  min-width: 970px;
  .layout-content {
    padding: 20px 60px  80px !important;
  }
  .form {
    .nonfocal-box {
      display: flex;
      flex-direction: row;
      .nonfocal {
        width: 340px;
        .image .el-upload--picture-card
        {
          width: 100px;
          height: 100px;
        }
        .image .el-upload-list__item {
          width: 100px;
          height: 100px;
        }
      }
    }
  }
  .el-input,
  .el-textarea {
    width: 320px;
  }
  .el-input-number {
    .el-input {
      width: auto;
    }
  }
  .ctrl-area{
    margin-top: 80px;
    .el-button{
      padding:10px  40px;
    }
  }
}
</style>
